
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../../utils/exceptionHandler.platform';
import { getChunkContainerByChunkId } from '../chunkElement';
import getRangyClassApplier from '../utils/getRangyClassApplier';
import { deserializeCanonicalRange } from '../utils/locationSerialization/chunked';
// eslint-disable-next-line import/no-cycle
import {
  chunkContainerHasContent,
  forceChunkContentLoadAtPosition,
  getChunkIdFromSerializedPosition,
  startChunkContainerIntersectionObservers,
  stopChunkContainerIntersectionObservers,
} from './chunkedContent';
import { ScrollingManagerCore } from './coreScrollingManager';

class WebScrollingManagerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'WebScrollingManagerError';
  }
}

export class WebScrollingManager implements ScrollingManagerCore {
  scrollableContainer: HTMLElement | undefined;
  contentRoot: HTMLElement | undefined;

  constructor() {
    this.scrollableContainer = document.getElementById('document-reader-root') ?? undefined;

    if (!this.scrollableContainer) {
      throw new WebScrollingManagerError('WebScrollingManager no scrollable container found');
    }

    this.contentRoot = document.getElementById('document-text-content') ?? undefined;

    if (!this.contentRoot) {
      throw new WebScrollingManagerError('WebScrollingManager no content root found');
    }
  }

  getScrollingElementTop(): number {
    return this.scrollableContainer?.scrollTop ?? 0;
  }

  async scrollToSerializedRange(serializedRange: string, offset: number): Promise<void> {
    const firstPositionInOccurrence = serializedRange.split(',')[0];
    if (!this.contentRoot) {
      throw new WebScrollingManagerError('ScrollToSerializedPosition no document text content container found');
    }

    try {
      // TODO: Perhaps we want to load the chunk which contains the last occurrence too
      const chunkId = getChunkIdFromSerializedPosition(firstPositionInOccurrence);
      if (!chunkId) {
        return;
      }
      const chunkContainer = getChunkContainerByChunkId(chunkId);
      if (!chunkContainer) {
        return;
      }
      if (!chunkContainerHasContent(chunkContainer)) {
        await forceChunkContentLoadAtPosition(firstPositionInOccurrence);
      }

      const range = deserializeCanonicalRange(
        serializedRange,
        this.contentRoot,
        document,
        getRangyClassApplier(),
      ).nativeRange;
      if (!range) {
        throw new WebScrollingManagerError(
          `scrollToSerializedRange no range found for serialized range ${serializedRange}`,
        );
      }

      await stopChunkContainerIntersectionObservers();

      if (!this.scrollableContainer) {
        throw new WebScrollingManagerError(
          `scrollToSerializedRange no scrollable container found for serialized range ${serializedRange}`,
        );
      }

      const rect = range.getBoundingClientRect();
      const scrollPosition = this.scrollableContainer.scrollTop + rect.top - offset;

      this.scrollableContainer?.scrollTo({
        top: scrollPosition,
        behavior: 'instant',
      });

      await startChunkContainerIntersectionObservers();
    } catch (e) {
      exceptionHandler.captureException(e);
    }
  }

  // No-op on web
  updateCurrentCenteredElement() {}
}
