import type { Update as TauriUpdate } from '@tauri-apps/plugin-updater';
import invert from 'lodash/invert';
import { AppStateStatus } from 'react-native';
import type { Operation } from 'readwise-fast-json-patch';
import { DeepReadonly, MangoQuery, RxCollection, RxDocument } from 'rxdb';

import type Database from '../database/Database';
// eslint-disable-next-line restrict-imports/restrict-imports
import type { Expression } from '../filters-compiler/types';
// eslint-disable-next-line restrict-imports/restrict-imports
import type { ISearchMatches } from '../foreground/documentSearchEngine';
// eslint-disable-next-line restrict-imports/restrict-imports
import { PromptsVersion } from '../foreground/ghostreader/constants';
// eslint-disable-next-line restrict-imports/restrict-imports
import { type OverriddenPrompts, CreatedPrompts } from '../foreground/ghostreader/types';
import type { NetworkStatus } from '../NetworkDetector';
import { commitId } from '../utils/environment';
import { FeedbackCategory, FeedbackSubCategory } from '../utils/feedback';
import type {
  ArrayWithAtLeastOneItem,
  ConvertSomeKeysToNever,
  WithRequiredMembers,
} from '../utils/typescriptUtils';
import type { DocumentChunkManifest } from './chunkedDocuments';
import type { HighlightResizeState } from './highlights';
import { KeyboardLayout, KeyboardShortcut } from './keyboardShortcuts';
import { QuoteshotAspectRatio, QuoteshotFont, QuoteshotThemeName, ThemeVariant } from './quoteshots';
import type { DocumentTagsObject } from './tags';
import { TextToSpeechInfo, TextToSpeechSettings } from './tts';

export interface RSSSuggestion {
  url: string;
  id: string;
  name?: string;
  description?: string;
  image_url?: string;
  domain: string;
  documents?: number;
  signal?: string;
  frequency?: number;
  lastPosted?: string;
}

export type ID = string;

export enum Category {
  Article = 'article',
  Email = 'email',
  RSS = 'rss', // eslint-disable-line @shopify/typescript/prefer-pascal-case-enums
  Highlight = 'highlight',
  Note = 'note',
  PDF = 'pdf', // eslint-disable-line @shopify/typescript/prefer-pascal-case-enums
  EPUB = 'epub', // eslint-disable-line @shopify/typescript/prefer-pascal-case-enums
  Tweet = 'tweet',
  Video = 'video',
}

/**
 * Categories that a top level document can be in.
 */
export const DocumentCategory = [
  Category.Article,
  Category.Email,
  Category.EPUB,
  Category.PDF,
  Category.Tweet,
  Category.Video,
] as const;

export type DocumentCategory = (typeof DocumentCategory)[number];

export const AllowedCategoryOverrides = [
  Category.Email,
  Category.PDF,
  Category.RSS,
  Category.Article,
  Category.Tweet,
  Category.Video,
  Category.EPUB,
];
export const CategoryToDisplayName = invert(Category) as { [category in Category]: string };

export enum ContentRequestLoadingStatus {
  Failed = 'failed',
  Loaded = 'loaded',
  Loading = 'loading',
  Unloaded = 'unloaded',
}

export enum ContentParsingStatus {
  Failed = 'fail',
  Pending = 'pending',
  ServerTaskNotStartedYet = 'task_not_started', // Client-side only
  Success = 'success',
}

export enum ReadingStatus {
  Archived = 'archived',
  Reading = 'reading',
  Unread = 'unread',
}

export enum FeedDocumentLocation {
  New = 'unseen',
  Seen = 'seen',
}

export enum DocumentLocation {
  Archive = 'archive',
  Feed = 'feed',
  Later = 'later',
  New = 'new',
  Shortlist = 'shortlist',
  Deleted = 'deleted',
}

export enum LocationSchemaVersion {
  Base = 1,
  Chunked = 2,
}

/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @shopify/typescript/prefer-pascal-case-enums */
export enum TshirtSize {
  '9XS' = '9XS',
  '8XS' = '8XS',
  '7XS' = '7XS',
  '6XS' = '6XS',
  '5XS' = '5XS',
  '4XS' = '4XS',
  '3XS' = '3XS',
  '2XS' = '2XS',
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  '2XL' = '2XL',
  '3XL' = '3XL',
  '4XL' = '4XL',
  '5XL' = '5XL',
  '6XL' = '6XL',
  '7XL' = '7XL',
  '8XL' = '8XL',
  '9XL' = '9XL',
  '10XL' = '10XL',
  '11XL' = '11XL',
  '12XL' = '12XL',
  '13XL' = '13XL',
  '14XL' = '14XL',
  '15XL' = '15XL',
  '16XL' = '16XL',
  '17XL' = '17XL',
  '18XL' = '18XL',
  '19XL' = '19XL',
  '20XL' = '20XL',
  '21XL' = '21XL',
  '22XL' = '22XL',
}

export const TshirtSizeDisplayName = {
  [TshirtSize.XS]: 'X-Small',
  [TshirtSize.S]: 'Small',
  [TshirtSize.M]: 'Medium',
  [TshirtSize.L]: 'Large',
  [TshirtSize.XL]: 'X-Large',
};

/* eslint-enable @shopify/typescript/prefer-pascal-case-enums */
/* eslint-enable @typescript-eslint/naming-convention */

export type UserInteractionName = NonNullable<UserEvent['userInteraction']>['name'];

export enum DisplayTheme {
  Default = 'default',
  Dark = 'dark',
  Light = 'light',
  System = 'system',
  Sepia = 'Sepia',
}

/* eslint-disable @shopify/typescript/prefer-pascal-case-enums */
export enum Font {
  Roboto = 'Roboto',
  AvenirNext = 'Avenir Next',
  HelveticaNeue = 'Helvetica Neue',
  Georgia = 'Georgia',
  Charter = 'Charter',
  NewYork = 'New York',
  SanFrancisco = 'San Francisco',
  Palatino = 'Palatino',
  SansSerif = 'Trebuchet MS',
  Times = 'Times New Roman',
  Inter = 'Inter',
  Literata = 'Literata',
  IBMPlexSans = 'IBM Plex Sans',
  Piazzolla = 'Piazzolla',
  SourceSans = 'Source Sans',
  SourceSerif = 'Source Serif',
  OpenDyslexic = 'OpenDyslexic',
  RobotoMono = 'Roboto Mono',
  AtkinsonHyperlegible = 'Atkinson Hyperlegible',
  PublicSans = 'Public Sans',
}

/* eslint-enable @shopify/typescript/prefer-pascal-case-enums */

export type TtsPosition = {
  trackPos: number; // Position in full document TTS track in seconds, modified by playback rate
  textPos: number; // Position in document in number of text characters from start (excludes HTML tags & attributes, includes whitespace)
  word: string; // Word at this position
  paraTextPos: number; // Word offset in the paragraph
  paraIndex: number; // The TTS paragraph
};

export type ReadingPosition = {
  scrollDepth: number;
  serializedPosition: string | null;
  mobileSerializedPositionElementVerticalOffset?: number;
  pageNumber?: number;
};

export type LenientReadingPosition = Omit<ReadingPosition, 'scrollDepth'> & {
  scrollDepth: ReadingPosition['scrollDepth'] | null;
};

export interface EditableKeys {
  author?: string;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  image_url?: string;
  summary?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  generated_summary?: string;
  language?: string;
  published_date?: number;
  category?: Category;
}

export interface OverridesForm {
  title?: string;
  author?: string;
  generated_summary?: string;
  summary?: string;
  tagsToRemove?: string[];
  docProgress?: number;
  coverImageUrl?: string;
  language?: string;
  publishedDate?: number;
  category?: Category;
}

export interface PDFHighlight {
  data: string;
  id: string;
  page: number;
  reader_file_id?: string;
}

interface GhostreaderHighlightMetadata {
  templatedPrompt: string;
  renderedPrompt: string;
}

// track position, text position, word as string, offset in paragraph, paragraph index, word duration
export type WordBoundary = [number, number, string, number, number, number];

export type EpubToc = {
  title: string;
  href: string;
  level: number;
  id: string;
};

// eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
export enum SidebarContentType {
  TableOfContent = 'TableOfContent',
  Thumbnails = 'Thumbnails',
}

// Keep in sync with reading-clients/shared/database/internals/defineSchema/documents.ts `rxdbOnly` schema key
// Boolean integers in the RxDB schema are represented as plain booleans in this Typescript definition.
export interface RxDBOnlyIndexFields {
  author: string;
  category: string;
  childrenCount: number;
  firstOpenedAtExists: boolean;
  hasHighlights: boolean;
  hasShortlistTag: boolean;
  isNewOrLater: boolean;
  showInUnseenAfter: number;
  showInSeenAfter: number;
  last_status_update: number;
  last_status_update_desc: number;
  lastOpenedAt: number;
  length_in_seconds: number;
  parsed_doc_id: number;
  published_date: number;
  randomNumber1: number;
  randomNumber2: number;
  randomNumber3: number;
  randomNumber4: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readingPosition_scrollDepth: number;
  saved_at: number;
  saved_at_desc: number;
  savedCount: number;
  startedReading: boolean;
  title: string;
  triage_status_exists: boolean;
  triage_status_is_feed: boolean;
}

export interface RxDBOnlyFields {
  lastRxDBUpdate: {
    id: string;
  };
  allTagsJoined: string;
  indexFields: RxDBOnlyIndexFields;
}

export interface BaseDocument {
  author?: string | null;
  category: Category;
  children?: DocumentId[];
  content?: string; // Highlight & notes only. See TransientDocumentData for Article content for example
  currentScrollPosition?: LenientReadingPosition | null;
  deleted_at?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  favicon_url?: string | null;
  firstOpenedAt?: number;
  generated_summary?: string;
  html?: string;
  id: DocumentId;
  image_url?: string | null;
  isExtensionActivated?: never; // No longer used
  isExtensionBarMinimized?: boolean;
  language?: string;
  lastOpenedAt?: number | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // it could be a timestamp number or timestamp string
  last_status_update?: number;
  lastSeenStatusUpdateAt?: number;
  location?: string;
  listening_time_seconds?: number;
  markdown?: string;
  notes?: string;
  offset?: number;
  overrides?: EditableKeys;
  parent?: DocumentId;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  parsed_doc_id?: number | string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  published_date?: number | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reading_status?: ReadingStatus;
  readingPosition?: LenientReadingPosition | null;
  sharedAt?: number | null;
  summary?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  saved_at: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  saved_at_history?: number[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  saved_from_feed_at?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  non_distributable?: boolean;

  // TODO: remove this after we are sure that everyone has the new client code
  // more info: https://linear.app/readwise/issue/RW-12156/remove-shows-in-feed-flag
  shows_in_feed?: boolean;
  source?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  site_name?: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  source_specific_data?: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    pdf_highlight?: PDFHighlight;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    rss_feed?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bookmark_id?: string;
    epub?: {
      toc?: EpubToc[];
      originalStylesEnabled?: boolean;
      is_chunked?: boolean; // ePub content is split into chunks (i.e. not concatenated into DocumentContent `html` property)
      is_drm_protected?: boolean;
      is_from_bookstore?: boolean;
      finished_at?: number;
    };
    current_chunk_id?: string;
    tweet?: { author_twitter_screen_name?: string; };
    ghostreader?: GhostreaderHighlightMetadata;
    paragraph_index?: number;
    pdf?: {
      layout?: PDFLayout;
      rotation?: number;
      sidebarContentType?: SidebarContentType;
      viewAsHtml?: boolean;
      zoom?: number;
    };
    email?: {
      from_email?: string;
      author_email?: string;
      can_subscribe?: boolean;
      originalEmailView?: boolean;
    };
    generated?: boolean;
    video?: {
      caption_language_code?: string;
      // E.g { 'en': 'English', 'es': 'Spanish' }
      caption_languages?: { [key: string]: string; };
      show_enhanced_youtube_transcript?: boolean;
      enhanced_youtube_transcript_status?: YouTubeEnhancedTranscriptStatus;
    };
    // For now we are only setting this after the user cleans a YouTube transcript using ChatGPT
    reparsed_at?: number;
    // only present in chunked docs, used to ensure old clients' serialized positions don't break.
    locationSchema?: {
      version: LocationSchemaVersion;
      migrated_from_unchunked: boolean;
      original?: {
        location?: string | null;
        currentScrollPosition?: LenientReadingPosition | null;
        readingPosition?: LenientReadingPosition | null;
      };
    };
  };
  tags?: DocumentTagsObject;
  title?: string;
  // Known to users as "location" and in a lot of our code as the "document location"
  // eslint-disable-next-line @typescript-eslint/naming-convention
  triage_status?: DocumentLocation | null;
  ttsPosition?: TtsPosition;
  updated?: number;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  word_count?: number;
  isPaginatedMode?: boolean;
  rxdbOnly?: RxDBOnlyFields;
  // This is a field that can be used to select documents in queries.
  // It should not be set when creating a document as RxDB will handle it.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _deleted?: boolean;
}

export interface ParentDocument extends BaseDocument {
  children: BaseDocument['id'][];
}

interface FirstClassBaseDocument
  extends ConvertSomeKeysToNever<
    ParentDocument,
    'content' | 'html' | 'location' | 'markdown' | 'offset'
  > {
  author: string | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  image_url: string | null;
  title: string;
  url: string;
  triage_status: DocumentLocation; // required for first class documents, not for highlights or notes
}

// TODO: could we extend EditableKeys? I couldn't find a way to reuse EditableKeys
// because for ovewrites all the keys should be optional but for the doc itself not
export interface Article extends FirstClassBaseDocument {
  category: Category.Article;
}

export interface Rss extends Omit<Article, 'category'> {
  category: Category.RSS;
}

export interface Email extends FirstClassBaseDocument {
  category: Category.Email;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  parsed_doc_id: string | number | null;
}

export interface Pdf extends FirstClassBaseDocument {
  category: Category.PDF;
}

export interface Epub extends FirstClassBaseDocument {
  category: Category.EPUB;
}

export interface Video extends FirstClassBaseDocument {
  category: Category.Video;
}

export interface Tweet extends Omit<Article, 'category'> {
  category: Category.Tweet;
}

export interface Highlight extends ParentDocument {
  category: Category.Highlight;
  html: string; // we keep this for debugging purposes
  location: string;
  markdown: string;
  offset: number;
  selected?: boolean;

  /*
    We previously had a bug which inserted this property erroneously. This prevents that & makes it
    unusable too.
  */
  text?: never;
}

export interface Note extends BaseDocument {
  category: Category.Note;
}

export type AnyDocument = Article | Email | Epub | Highlight | Note | Pdf | Rss | Tweet | Video;
export type DocumentWithParent = Highlight | Note;
export type FileDocument = Pdf;
export type DocumentWithAuthor = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentWithLanguage = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentWithParsedDocId = Article | Email | Pdf | Rss | Epub | Tweet | Video;
// For now, a FirstClassDocument is any Document that renders in the DocumentList and DocumentContent view
export type FirstClassDocument = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentThatCanBeShared = Article | Email | Rss | Tweet | Video;
export type DocumentWithTitle = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentThatCanHaveHighlights = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentWithUrl = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentWithThirdPartyUrl = Article | Pdf | Rss | Tweet | Video;
export type DocumentWithWordCount = Article | Email | Pdf | Rss | Epub | Tweet;
export type DocumentWithPublishedDate = Article | Email | Pdf | Rss | Epub | Tweet | Video;
export type DocumentWithSummary = Article | Email | Rss | Pdf | Epub | Tweet | Video;
export type DocumentWithEmptyStateInstructionsSidebar = Article | Email | Rss | Pdf | Epub | Tweet;

export type PartialDocument<
  TDoc extends AnyDocument,
  TOnlyKey extends KeyOfDocumentWithTransientData<TDoc>,
> = Pick<DocumentWithTransientData<TDoc>, TOnlyKey>;

export type ReducedHighlight = Pick<Highlight, 'content' | 'html' | 'id' | 'location'>;

export enum TableSortKey {
  Name = 'name',
  Description = 'description',
  Query = 'query',
  Documents = 'documents',
  HighlightsCount = 'HighlightsCount',
  Views = 'views',
  LastUpdated = 'lastUpdated',
  SelectAll = 'selectAll',
  Frequency = 'frequency',
  Manual = 'manual',
  Folders = 'folders',
}

export type TableHeader = {
  title: string;
  sortkey: TableSortKey;
  width?: string;
  minWidth?: string;
  isLoading?: boolean;
};

export enum SortKey {
  Author = 'author',
  Category = 'category',
  LastOpenedAt = 'lastOpenedAt',
  LastStatusUpdate = 'last_status_update',
  Published = 'published_date',
  SavedAt = 'saved_at',
  WordCount = 'word_count',
  ReadingProgress = 'reading_progress',
  Title = 'title',
  UpdatedAt = 'updated',
  Random = 'random', // This must be last
}

export enum BookwiseSortKey {
  All = 'all',
  Unfinished = 'unfinished',
  Finished = 'finished',
}

/**
 * Sort keys that are displayed in the sort palette.
 */
export const DisplayableSortKeys = Object.values(SortKey).filter((key) => key !== SortKey.UpdatedAt);

/**
 * Type representing the keys of DisplayableSortKeys.
 */
export type DisplayableSortKey = Exclude<SortKey, SortKey.UpdatedAt>;

export enum SortOrder {
  Desc = 'desc',
  Asc = 'asc',
}

export type ManualSortOverride = DocumentId[];

export interface SortRule {
  readonly id: string;
  key: SortKey;
  order: SortOrder;
  manualOverride?: ManualSortOverride;
}

export type DocumentId = ID;

export type DocumentContent = {
  id: string; // Parsed document ID, NOT document ID
  html: string;
  status: ContentParsingStatus;
  url: string;
  tts?: { [voice: string]: { word_boundaries_v3_chunked: {[chunkId: string]: WordBoundary[]; };}; };
  tts_status: ContentParsingStatus;
  // map from index (as string) to document chunk IDs, if document is chunked. modeled after ePub format.
  spine?: { [index: string]: string; };
  // map from internal chunk ID to ParsedDocumentChunk ID if document is chunked. modeled after ePub manifest.
  manifest?: DocumentChunkManifest;
};

// This has to match Profile.FULL, etc in models.py:
export enum SubscriptionProduct {
  Full = 'full',
  Lite = 'lite',
  Beta = 'beta',
  LiteBeta = 'lite_beta',
  Expired = 'expired',
  Trial = 'trial',
}

export const SubscriptionProductDisplay = {
  [SubscriptionProduct.Full]: 'Readwise Full',
  [SubscriptionProduct.Lite]: 'Readwise Lite',
  [SubscriptionProduct.Beta]: 'Readwise Beta',
  [SubscriptionProduct.LiteBeta]: 'Readwise Lite',
  [SubscriptionProduct.Expired]: 'Expired',
  [SubscriptionProduct.Trial]: 'Free Trial',
};

export enum ReadingProgressViewVariant {
  Percent = 'percent',
  TimeInDocument = 'TimeInDocument',
  Hidden = 'Hidden'
}

export interface Profile {
  original_library_email: string;
  custom_feed_email: string;
  custom_library_email: string;
  email: string;
  first_name: string; // Could be empty string
  last_name?: string; // Could be empty string
  username: string;
  has_reader_access: boolean;
  is_staff: boolean;
  disable_persistent_query_cache?: boolean;
  is_i: boolean; // True if this profile is being impersonated by a staff account
  legacy_feed_email: string;
  minimal_mobile_version_supported: string;
  profile_id: string;
  subscription: {
    product: SubscriptionProduct;
    trial_days_left: number;
    was_previously_subscribed: boolean;
    subscribed_via_apple: boolean;
  };
  email_preferences: {
    unsubbed_from_feedback_emails: boolean;
    unsubbed_from_wisereads_emails: boolean;
    unsubbed_from_daily_summary_emails: boolean;
  };
  should_prompt_review: boolean;
}

export enum RssFeedPopularity {
  VeryPopular = 'very_popular',
  Popular = 'popular',
  SomewhatPopular = 'somewhat_popular',
  RarelyAdded = 'rarely_added',
  Unknown = 'unknown',
}

export const RSSFeedPopularityDisplay: { [key in RssFeedPopularity]: string } = {
  [RssFeedPopularity.VeryPopular]: 'Very popular',
  [RssFeedPopularity.Popular]: 'Popular',
  [RssFeedPopularity.SomewhatPopular]: 'Somewhat popular',
  [RssFeedPopularity.RarelyAdded]: 'Rarely added',
  [RssFeedPopularity.Unknown]: 'Popularity unknown',
};

export interface RSSSuggestion {
  url: string;
  id: string;
  name?: string;
  description?: string;
  image_url?: string;
  domain: string;
  popularity: RssFeedPopularity;
  last_entry_added_at: number;
}

export interface MinimalRss {
  url: string;
  db_feed_id?: string;
}

export interface RssFeed {
  name?: string;
  description?: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  image_url?: string;
  created: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  db_feed_id?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  last_updated?: number;
  popularity?: RssFeedPopularity;
  last_entry_added_at?: number;
}

export enum SplitByKey {
  DocumentLocation = 'triage_status',
  Seen = 'seen',
}

export interface SplitByValue {
  name: string;
  queryParamValue: string;
}

export interface FilteredView {
  readonly id: string;
  name: string;
  query: string;
  description?: string;
  icon?: string;
  readonly created?: number;
  sortRules: ArrayWithAtLeastOneItem<SortRule>;
  splitBy?: SplitByKey;
  alias?: string;
  isUnpinned?: boolean;
  order?: number;
  showCountBadge?: boolean;
  expires_at?: number;
  sharedAsBundle?: boolean;
  rssFolderId?: string;
  extraData?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export type FilteredViewWithOptionalId = Omit<FilteredView, 'id'> & {
  id?: string;
};

export interface RssFolder {
  readonly id: string;
  filteredViewId: string;
  isCollapsedInSidebar: boolean;
  isCollapsedInMobileSidebar: boolean;
  childrenRssItems: RssItem[];
}

export interface RssItem {
  readonly id: string;
  rssSourceId: string;
  rssFolderId?: string;
  showCountBadge?: boolean;
}

export type RssFolderOrItem = RssFolder | RssItem;

export interface SplitTab {
  count?: number;
  title: string;
  toUrl: string;
  isActive: boolean;
}

// This defines the state of the current focused list
export type MobileFocusedListState = {
  isFilteredView: boolean | undefined;
  isFeedView: boolean | undefined;
  savedFilterView: FilteredView | undefined;
  filterQuery: string | undefined;
  routeName: string | undefined;
};

export type DigestSettings = {
  enabled: boolean;
};

export enum DefaultPage {
  Library = 'library',
  Home = 'home',
  Search = 'search',
  Feed = 'feed',
  CurrentlyReading = 'currentlyReading',
}

export type SettingsState = {
  defaultPage?: DefaultPage;

  /*
    One of these:
      [DocumentLocation.Feed, DocumentLocation.Later, DocumentLocation.Shortlist, DocumentLocation.Archive]
      [DocumentLocation.Feed, DocumentLocation.New, DocumentLocation.Later, DocumentLocation.Archive]
      [DocumentLocation.Feed, DocumentLocation.Later, DocumentLocation.Archive]

    We would define this as a union of those arrays but it breaks things downstream, e.g:
    `documentLocations.include(DocumentLocation.New)` is highlighted as a TS error because `DocumentLocation.New`
    doesn't exist in every array above.
  */
  documentLocations: DocumentLocation[];
  digest?: DigestSettings;
  syncing?: {
    pocketFullSync?: boolean;
  };
  extension?: {
    isAutoHighlightingEnabled: boolean;
  };
  mobile?: {
    autoHighlight: boolean;
  };
  tts_v2?: TextToSpeechSettings;
  twitter?: {
    saveSingleTweetsInReadwise?: boolean;
    saveSingleTweetsInReader?: boolean;
    saveThreadsInReadwise?: boolean;
    saveThreadsInReader?: boolean;
  };
  openai?: {
    apiKey?: string;
    isAutoSummarizeEnabled?: boolean;
    isAutoTaggingEnabled?: boolean;
  };

  overriddenPromptsVersion?: PromptsVersion;
  overriddenPrompts?: OverriddenPrompts;
  overriddenPrompts2?: OverriddenPrompts;
  createdPrompts?: CreatedPrompts;

  web?: {
    isAutoHighlightingEnabled: boolean;
  };

  exportClipboardTemplate?: string;
};

export enum JobStatus {
  Queued = 'queued',
  Started = 'started',
  Success = 'success',
  Failure = 'failure',
}

export enum JobType {
  ParseNewDocument = 'parse-new-document',
  RssImport = 'rss-import',
  MigrateTwitterThreads = 'migrate-twitter-threads',
  GenerateFirstDailyDigest = 'generate-first-daily-digest',
  GenerateEmailForAppInstall = 'generate-email-for-app-install',
  SetUpNewSpace = 'set-up-new-space',
  FullPocketImport = 'full-pocket-import',
  SummarizeDocument = 'summarize-document',
  UpdateEmailSubscriptionStatus = 'toggle-email-subscription-status',
  UpdateBundle = 'update-bundle',
  UpdateImportEmailAddress = 'update-import-email-address',
  ToggleOriginalEmailViewStatus = 'toggle-original-email-view-status',
  EnhanceYouTubeTranscript = 'enhance-youtube-transcript',
  ReparseUserYoutubeDocument = 'reparse-user-youtube-document',
}

export type MobileTableOfContentsItem = {
  id: string;
  headingDisplay: string;
  headingLevel: number;
  page?: number;
};

export type Job = {
  id: string;
  created: number;
  status: JobStatus;
  type: JobType;
  arguments: {
    [key: string]: unknown;

    // (Optional) large arguments won't be persisted to state on the server, as they take up too much space.
    large?: { [key: string]: unknown; };
  };
  resultData: {
    [key: string]: unknown;
  };
};

export enum OnboardingStep {
  Welcome = '/welcome',
  Extension = '/welcome/extension',
  MobileApp = '/welcome/mobile',
  Ready = '/welcome/ready',
  New = '/new',
}

export const OnboardingStepOrder = [
  OnboardingStep.Welcome,
  OnboardingStep.Extension,
  OnboardingStep.MobileApp,
  OnboardingStep.Ready,
  OnboardingStep.New,
];

export const MobileOnboardingStepOrder = [
  OnboardingStep.Welcome,
  OnboardingStep.Extension,
  OnboardingStep.Ready,
  OnboardingStep.New,
];

export type IdToDocumentMap<T = AnyDocument> = { [id: string]: T; };

export interface PersistentState {
  bookwiseMobileNotificationToken?: string;
  currentlyReadingId: AnyDocument['id'] | null;
  experience?: number;
  experience_total?: number;
  currentlyReadingListQuery?: string | MangoQuery<AnyDocument> | null;
  customKeyboardShortcuts?: {
    [key: string]: string[];
  };
  dailyDigestProgress: {
    currentIndex: number;
    done: boolean;
  };
  deletedRssSuggestions: {
    high?: string[];
    low?: string[];
  } | null;
  filteredViews: { [key: string]: FilteredView; };
  rssFoldersAndItems: RssFolderOrItem[];
  home?: {
    views: FilteredView['id'][];
  };
  integrations: {
    instapaper?: {
      accountEmail?: string;
      connected: boolean;
    };
    pocket?: {
      accountEmail?: string;
      connected: boolean;
    };
    twitter?: {
      handle?: string;
      connected: boolean;
    };
    gmail?: {
      handle?: string;
      connected: boolean;
    };
    kindle?: {
      emailToSendDocuments?: string;
      isAutomaticKindleEmailEnabled?: boolean;
      exportFrequency?: 'weekly' | 'daily';
      lastDigestSentAt?: number;
    };
  };
  jobs: {
    [id: string]: Job;
  };
  keyboardLayout?: KeyboardLayout;
  keyboardShortcutsBlackList?: string[];
  mobileNotificationToken?: string;
  mobileOnboardingStep: OnboardingStep;
  onboardedAt?: number | null;
  onboardingStep: OnboardingStep;
  rssFeeds: { [key: string]: RssFeed; } | null;
  settings: SettingsState;
  time_of_last_fetch?: number;
  summaryEmailSent?: boolean;
  emailSubscriptions: {
    [key: string]: {
      subscribed: boolean;
    };
  } | null;
  hasBeenShownReview?: boolean;
}

export interface PersistentStateWithDocuments extends PersistentState {
  documents: IdToDocumentMap;
}

// Absolute minimum / most empty as possible
export interface MinimalPersistentState extends PersistentState {
  currentlyReadingId: null;
  // To be safe, `jobs` can be non-empty
  rssFeeds: null;
  filteredViews: { [key: string]: never; };
  settings: Omit<PersistentState['settings'], 'tts' | 'tts_v2'>;
}

export type DocumentSpine = { [index: string]: string; };

export type TransientDocumentData = {
  content?: string;
  contentParsingStatus: ContentParsingStatus;
  contentRequestLoadingStatus: ContentRequestLoadingStatus;
  searchMatches?: ISearchMatches;
  selected?: boolean;
  tts?: {
    [voice: string]: {
      word_boundaries_v3_chunked?: {[chunkId: string]: WordBoundary[];};
      word_boundaries_v2_chunked?: {[chunkId: string]: WordBoundary[];};
    };
  };
  ttsParsingStatus?: ContentParsingStatus;
  ttsPosition?: TtsPosition;

  // -- below are properties used for chunked documents, which is still an ongoing project and not yet shipped to users.
  // map from index (as string) to document chunk IDs, if present. modeled after ePub format.
  spine?: DocumentSpine;
  // map from internal chunk ID to ParsedDocumentChunk ID if present. modeled after ePub manifest.
  manifest?: DocumentChunkManifest;
};

export type DocumentWithTransientData<T extends AnyDocument = AnyDocument> = T & {
  transientData: TransientDocumentData;
};

export type DocumentWithOptionalTransientData<T extends AnyDocument = AnyDocument> = T & {
  transientData?: TransientDocumentData;
};

export type KeyOfDocumentWithTransientData<T extends AnyDocument> = keyof DocumentWithTransientData<T>;

export type PDFLayout = 'Continuous' | 'FacingContinuous';
export type DocumentClientState = {
  ttsSettings?: {
    wordTrackingOffset?: number;
  };
};

export enum DocListSwipeActionEnum {
  AllActions = 'AllActions',
  MoveToArchive = 'MoveToArchive',
  MoveToInbox = 'MoveToInbox',
  MoveToLater = 'MoveToLater',
  MoveToShortlist = 'MoveToShortlist',
  ToggleShortlistTag = 'ToggleShortlist',
  ToggleSeen = 'ToggleSeen',
  MarkAboveAsSeen = 'MarkAboveAsSeen',
  MarkBelowAsSeen = 'MarkBelowAsSeen',
  MarkAboveAsUnseen = 'MarkAboveAsUnseen',
  MarkBelowAsUnseen = 'MarkBelowAsUnseen',
  NoteAndTags = 'NoteAndTags',
  DeleteDocument = 'DeleteDocument',
  DeleteAbove = 'DeleteAbove',
  DeleteBelow = 'DeleteBelow',
}

// The order matters
export enum SwipeDocList {
  LibraryInbox = 'libraryInbox',
  LibraryLater = 'libraryLater',
  LibraryShortlist = 'libraryShortlist',
  LibraryArchive = 'libraryArchive',
  FeedNew = 'feedNew',
  FeedSeen = 'feedSeen',
}

export type SwipeType = 'leftLong' | 'left' | 'right' | 'rightLong';

export type SwipeConfig = {
  [key in SwipeDocList]: {
    [type in SwipeType]: DocListSwipeActionEnum;
  };
};

export type YouTubePlaybackRate = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;

export type YouTubeClientState = {
  autoScroll: boolean | null;
  playbackRate: YouTubePlaybackRate;
  playerHeight: number | null;
};

export enum TextDirection {
  LeftToRight = 'ltr',
  RightToLeft = 'rtl',
}

export const KNOWN_ERROR_REASONS = [
  'invalid_parameter',
  'HTML5_error',
  'video_not_found',
  'embed_not_allowed',
] as const;

export type KnownYouTubePlayerErrorReason = typeof KNOWN_ERROR_REASONS[number];

export type YouTubePlayerErrorReason = KnownYouTubePlayerErrorReason | 'unknown';

export type MobileImageModalData = {
  src: string | undefined;
  id: string;
  highlighted: boolean;
  highlightId?: string;
} | null;

export enum SubMenu {
  AccountIssue = 'AccountIssue',
  AddFeedFolder = 'AddFeedFolder',
  AllCommands = 'AllCommands',
  BrowseFilteredView = 'BrowseFilteredView',
  BugReport = 'BugReport',
  BulkActions = 'BulkActions',
  BulkActionsTagsAbove = 'BulkActionsTagsAbove',
  BulkActionsTagsAll = 'BulkActionsTagsAll',
  BulkActionsTagsBelow = 'BulkActionsTagsBelow',
  CreateFilteredView = 'CreateFilteredView',
  DeleteFeeds = 'DeleteFeeds',
  DocumentActions = 'DocumentActions',
  DocumentGptPrompt = 'DocumentGptPrompt',
  DocumentInboxActions = 'DocumentInboxActions',
  EditFeed = 'EditFeed',
  EditTag = 'EditTag',
  EditView = 'EditView',
  EditLanguageSheet = 'EditLanguageSheet',
  FeatureRequest = 'FeatureRequest',
  Feedback = 'Feedback',
  FeedbackReport = 'FeedbackReport',
  Feeds = 'Feeds',
  FilterAllDocuments = 'FilterAllDocuments',
  FontSettings = 'FontSettings',
  HighlightGptPrompt = 'HighlightGptPrompt',
  InputGptPrompt = 'InputGptPrompt',
  LinkActions = 'LinkActions',
  ManageFeedsInFolder = 'ManageFeedsInFolder',
  ManageFoldersForFeed = 'ManageFoldersForFeed',
  // TODO: legacy prompt, remove after customized prompts v2 has shipped
  ManualGptPrompt = 'ManualGptPrompt',
  None = 'None',
  Normal = 'Normal',
  ParseError = 'ParseError',
  ProductQuestion = 'ProductQuestion',
  ReferFriend = 'ReferFriend',
  RemoveFilteredView = 'RemoveFilteredView',
  SaveDocFromUrl = 'SaveDocFromUrl',
  SaveFilteredView = 'SaveFilteredView',
  Search = 'Search',
  SelectSplitBy = 'SelectSplitBy',
  Shortcuts = 'Shortcuts',
  SortList = 'SortList',
  StyleSettings = 'StyleSettings',
  ExtraStyleSettings = 'ExtraStyleSettings',
  PaginationDefaults = 'PaginationDefaults',
  Tags = 'Tags',
  Theme = 'Theme',
  Triage = 'Triage',
  TriageFromInbox = 'TriageFromInbox',
  TtsSpeechRates = 'TtsSpeechRates',
  TtsVoices = 'TtsVoices',
  // Only supported on mobile
  SubscribeToFeed = 'SubscribeToFeed',
  ConfigureHome = 'ConfigureHome',
  CreateViewFromTag = 'CreateViewFromTag',
  DocNote = 'DocNote',
  EditQuery = 'EditQuery',
  EditUnsavedQuery = 'EditUnsavedQuery',
  AddFeedActions = 'AddFeedActions',
  ExportAllHighlightActions = 'ExportAllHighlightActions',
  FeedActions = 'FeedActions',
  FeedsActions = 'FeedsActions',
  FilterQuery = 'FilterQuery',
  Ghostreader = 'Ghostreader',
  NotebookHighlightActions = 'NotebookHighlightActions',
  Login = 'Login',
  SaveView = 'SaveView',
  ShareDocument = 'ShareDocument',
  Signup = 'Signup',
  SortFeeds = 'SortFeeds',
  SortTags = 'SortTags',
  SortViews = 'SortViews',
  SwipeActions = 'SwipeActions',
  TagActions = 'TagActions',
  ViewActions = 'ViewActions',
  ImageActions = 'ImageActions',
  ChangeEmail = 'ChangeEmail',
  ChangeImportEmailAddress = 'ChangeImportEmailAddress',
  ResetPassword = 'ResetPassword',
  HighlightNote = 'HighlightNote',
  HighlightTags = 'HighlightTags',
  EditMetadata = 'EditMetadata',
  AddDocument = 'AddDocument',
  HandleTrashItem = 'HandleTrashItem',
  ExperienceWin = 'ExperienceWin',
  YouTubeCaptionsLanguageList = 'YouTubeCaptionsLanguageList',
}

export type MobileDocumentSubpanel = SubMenu.DocNote | SubMenu.Tags;
export type MobileHighlightSubpanel = SubMenu.HighlightNote | SubMenu.HighlightTags;

export type ClientState = {
  theme: DisplayTheme;
  recentSearches: string[];
  profile: Profile | null;
  rightSidebarHiddenInList: boolean;
  hideRightPanelOnEnteringReadingView: boolean;
  hideLeftPanelOnEnteringReadingView: boolean;
  readerSettings: {
    desktop: {
      fontSize: TshirtSize;
      lineHeight: TshirtSize;
      lineLength: TshirtSize;
      font: Font;
      direction: TextDirection;
      justifyText: boolean;
    };
    mobile: {
      fontSize: TshirtSize;
      lineHeight: TshirtSize;
      pageWidth: TshirtSize;
      font: Font;
      justifyText: boolean;
      direction: TextDirection;
      arePaginationAnimationsDisabled: boolean;
      arePaginationHapticsOnScrollEnabled: boolean;
      paginationOnByDefaultList: Category[];
    };
  };
  isGhostreaderEnabled: boolean;
  bookwise?: {
    sortKey?: BookwiseSortKey;
    areWipFeaturesEnabled?: boolean;
  };
  isHighContrastMode: boolean | null;
  showDailyReview: boolean | null;
  listSortRules: {
    [listId: string]: SortRule[];
  };
  dailyDigestHomeNudgeDisabled: boolean;
  largeFeedView: boolean;
  documents: { [key: AnyDocument['id']]: DocumentClientState; };
  askToPasteUrls: boolean;
  keepAwakeWhileReading: boolean;
  mobileHapticsEnabled: boolean;
  openLinksInApp: boolean;
  swipeConfig: SwipeConfig;
  autoAdvance: boolean | undefined;
  shouldOpenReaderLinksInDesktopApp: boolean | undefined;
  youtube: YouTubeClientState;
  mobileLastAnnotationSubpanelOpened?: MobileDocumentSubpanel;
  mobileHomeNavBarHeight: number;
  mobileTapToShowMenusDisabled: boolean;
  mobileReadingProgressButtonDisabled: boolean;
  sortViewsByKey: TableSortKey;
  sortViewsByOrder: SortOrder;
  sortFeedsByKey: TableSortKey;
  sortFeedsByOrder: SortOrder;
  sortTagsByKey: TableSortKey;
  sortTagsByOrder: SortOrder;
  headphoneGestures: {
    previousTrack: HeadphoneAction;
    nextTrack: HeadphoneAction;
  };
  mobileDeveloperSettings: {
    showReadingViewLoadingTime: boolean;
    showPdfFileFetchedTime: boolean;
    showInspectorAlertInWebview: boolean;
    showFpsCounter: boolean;
    simulateSlowChunkLoading: boolean;
    logWebviewFPS: boolean;
    disableXpTracking: boolean;
  };
  quoteshot: {
    currentThemeName: QuoteshotThemeName;
    aspectRatio: QuoteshotAspectRatio;
    currentFont: QuoteshotFont;
    isDarkMode: boolean;
    variantForTheme: {
      [themeName in QuoteshotThemeName]: ThemeVariant['id'];
    };
  };
  mobileSafeAreaInsets: { top: number; left: number; bottom: number; right: number; };
  shouldInvertPDFColors: boolean;
  mobileShouldUseVolumeButtonsToScrollPages: boolean;
  mobileAreAnimationsDisabled: boolean | null;
  mobileAppIcon: AppIcon;
  databaseExplorerState?: DatabaseExplorerState;
  navigationSidebar: {
    isWebNavigationSidebarHidden: boolean;
    isLibraryCollapsed: boolean;
    isTagsCollapsed: boolean;
    isFeedCollapsed: boolean;
    isPinnedViewsCollapsed: boolean;
  };
  mobileReadingProgressViewVariant: ReadingProgressViewVariant;
  // Used to revalidate queries for deleted documents when the trash is emptied
  trashRevalidationToken: string;
  isDocumentSummaryContainerExpanded?: boolean;
  isDocumentListSummaryContainerExpanded?: boolean;
};

export enum AppIcon {
  Modern = 'AppIconModern',
  ModernDark = 'AppIconModernDark',
  Classic = 'AppIconClassic',
  ClassicDark = 'AppIconClassicDark',
}

export type DatabaseExplorerState = {
  currentMangoQuery: MangoQuery<AnyDocument>;
  currentFilterQuery: string;
};

export enum HeadphoneAction {
  Highlight = 'highlight',
  JumpForward = 'jump_forward',
  JumpBackward = 'jump_backward',
}

// TODO: do we have to add Category.Video here?
export type EmptyStateCategory =
  | Category.Article
  | Category.EPUB
  | Category.Email
  | Category.PDF
  | Category.RSS
  | Category.Tweet;

/*
  NOTE: FullZustandState.client persists on a single device, and FullZustandState.persistent persists across all devices.
  Every other piece of state simply lives in memory and will be wiped when the app/webapp closes.

  When updating this, check if you need to make changes to `background.setUpInitialState`.
*/
export type DocumentList = {
  deps: {
    categoryToFilterAndSortWith: Category | null;
    filterAst: Expression | null;
    filterByDocumentLocation: DocumentLocation | null;
    shouldRunSplit: boolean;
    splitByKey: SplitByKey | null;
    splitByValue: string | null;
  };
  docIdsMap: {
    [id: string]: true;
  };
  filteredViewId: FilteredView['id'] | null;
  id: string;
  updatedAt: number;
};

export type YouTubeState = {
  seekTo: number | null;
  isPlaying: boolean;
};

export enum PersistentStateLoadingState {
  HasNotStarted,
  DownloadingDocuments,
  AddingDocumentsToDatabase,
  Done,
  Failed,
}

// TODO: ADD LINT RULE TO DISABLE OPTIONAL KEYS here
// TODO: Make all undefined values null values
// Please do not add optional keys to this object (for reasons see https://github.com/readwiseio/rekindled/pull/3162)
// If its "optional" set its default value to null
export type FullZustandState = {
  activeSchemaMigration?: boolean;
  areAllDatabaseHooksDisabled: boolean;
  canUndoAction: boolean;
  client: ClientState;
  clientStateLoaded: boolean;
  desktopPendingUpdate?: DesktopPendingUpdate;
  haveSomeDocumentContentItemsLoaded: boolean;
  openDocumentId: FirstClassDocument['id'] | null;
  persistentStateLoaded: boolean;
  persistentStateLoadingState: PersistentStateLoadingState;
  persistentStateTotalDocumentsToAddCount: string;
  persistentStateNumberOfDocumentsAdded: number;
  areViewsDocumentCountsIndexing: boolean;
  areFeedsDocumentCountsIndexing: boolean;
  areServerUpdatesBeingAppliedToForeground: boolean;
  areServerUpdatesBeingFetchedByUser: boolean;
  cmdPalette: CmdPalette;
  quoteshotModalOpen: boolean;
  quoteshotHighlightId: string | null;
  documentsListScrolled: boolean;
  documentSummaryController: AbortController | null;
  filterPreviousRoute: string;
  focusedDocumentId: DocumentId | null;
  focusedDocumentListQuery: MangoQuery<AnyDocument> | null;
  focusedFeedId: string | null;
  focusedTagId: string | null;
  focusedViewId: string | null;
  focusedHighlightId: string | null;
  gptPromptLoading: boolean;
  gptPrompt: {
    selection?: string;
    expandedSelection?: string;
    surroundingParagraphContents?: string;
    systemPrompt?: string;
    prompt?: string;
    newDocument?: boolean;
  } | null;
  highlightIdToOpenAt: DocumentId | null;
  highlightResizeState: HighlightResizeState;
  isAppearancePanelShown: boolean;
  isMigratingSearchDatabase: boolean;
  isMobileCPUThrottled: boolean;
  isMobileAppReviewDebugging: boolean;
  isVideoHeaderShown: boolean;
  isVideoSettingsPanelShown: boolean;
  isDeleteDocumentDialogOpen: boolean;
  isDocMoreActionsDropdownOpen: boolean;
  isNotebookDropdownOpen: boolean;
  isEditTagsPopoverShown: boolean;
  isDocumentMetadataShown: boolean;
  isDocumentsSortMenuShown: boolean;
  isDocumentSummaryGenerating: boolean;
  emptyStateCategory: EmptyStateCategory | null;
  isOnline: boolean;
  isStateMinimized: boolean;
  keyboardShortcuts: {
    [id: string]: KeyboardShortcut;
  };
  leftSidebarHiddenForNarrowScreen: boolean;
  leftSidebarHiddenInReadingView: boolean;
  linkActionsUrl: string | null;
  mobileActiveTableOfContentsHeadingId: MobileTableOfContentsItem['id'] | undefined;
  mobileAppState: AppStateStatus;
  mobileCurrentFocusedDocumentListId: string | undefined;
  mobileIdForNotebookHighlightActionsSheet: string | null;
  mobileDocumentImageModalData: MobileImageModalData;
  mobileImageModalDataLinkAction: MobileImageModalData;
  mobileDocumentTableOfContents: MobileTableOfContentsItem[];
  mobileFocusedListState: MobileFocusedListState;
  // This refers to LoggedInRootContainer, not the home screen
  mobileIsEmptyListState: boolean;
  mobileLoggedIn: boolean | null;
  mobileActiveTab: string | null;
  mobileSelectedFilteredViewQuery: string | null;
  modal: string | null;
  networkStatus: NetworkStatus;
  openNotebookId: BaseDocument['id'] | null;
  persistent: PersistentState;
  possibleRssFeeds: { [key: string]: { url: string; name?: string; } | null; };
  rightSidebarHiddenForNarrowScreen: boolean;
  rightSidebarHiddenInReadingView: boolean;
  routeStack: string[];
  screenWidth: number;
  sendToKindleDocId: string | null;
  tagNamesUsedRecently: string[]; // Reverse-chronological
  temporarilyCachedFeedSuggestions: RSSSuggestion[] | null;
  temporarilyCachedFeedSuggestionsSelection: { high: string[]; low: string[]; } | null;
  transientDocumentsData: {
    [documentId: string]: TransientDocumentData;
  };
  // The presence of this value indicates that the user was listening to a document at some point recently.
  // It does NOT indicate that they are currently listening to that document. For that, use useTrackPlayerState().
  tts: TextToSpeechInfo | null;
  ttsFetchingTimestamp: boolean;
  update: (
    updater: (state: FullZustandState) => void,
    options: {
      correlationId?: UserEvent['correlationId'];
      // This is used as the event name if one is created but also as an update name for Redux dev tools
      eventName: UserEvent['name'];
      isUndoable?: boolean;
      onSentToServer?: () => void;
      shouldCreateUserEvent?: boolean;
      shouldNotSendPersistentChangesToServer?: boolean;
      userInteraction: StringOrUnknownString | null;
    },
  ) => Promise<{ userEvent?: UserEvent; }>;
  webEffectiveTheme: DisplayTheme;
  zenModeEnabled: boolean;
  youtube: YouTubeState;
  filterQueryToCreate: string | null;
  isPdfSnapToolEnabled: boolean;
  openedAnnotationBarPopoverHighlightId: string | null;
  pathNameToRedirectAfterOnboarding: string | null;
  filteredViewIdToDelete: string | null;
  feedIdToDelete: string | null;
  feedIdsToAddInFolder: string[];
  shouldRunSidebarItemCounts: boolean;
};

export type StateFetchMeta = {
  time_of_last_fetch: number;
  was_incremental_sync: boolean;
  documents_checksum?: number;
  pagination_next_start_id?: string;
};

export type DesktopPendingUpdate = {
  status: 'readyForDownload' | 'readyForInstall' | 'downloading' | 'failed';
  update: TauriUpdate;
};

export type ItemReference<TAllowedItemTypes = string> = {
  id: ID;
  type: TAllowedItemTypes;
};
export type Patch = Operation[];

export type DataUpdatesItemUpdatedReference = ItemReference<keyof PersistentStateWithDocuments>;

export type DataUpdates = {
  forwardPatch: Patch;
  itemsUpdated: DataUpdatesItemUpdatedReference[];
  reversePatch: Patch;
};

/*
  Objects are preferred, to allow for new keys in future.
*/
export type UserEvent = {
  // An ID used through an event chain. It can be the first event ID
  correlationId: UserEvent['id'] | null;
  dataUpdates?: DataUpdates;
  environment: {
    agent: {
      // App / API / backend
      category: 'api' | 'backend' | AppCategory;
      version: StringOrUnknownString; // App / API version
    };
    app?: {
      category: AppCategory;
      commitId?: typeof commitId;
      page: {
        // If a page for a specific item is open, e.g. when viewing an individiual document.
        itemId: ID | null;
        name: string;
      };
      otherVersions?: {
        // e.g. Node.js version, library versions, etc.
        [key: string]: StringOrUnknownString;
      };
      sessions: {
        focusSessionId: ID | null; // Created whenever the app gains focus, null if unfocused
        instanceSessionId: ID; // Unique per app launch
        pageSessionId: ID; // Created whenever the top-level URL / page is changed
        // For web & mobile apps, this is created per app launch. For desktop apps, this is created per window.
        windowSessionId: ID;
      };
      version: AppVersion;
    };
    browser?: BrowserInfo & {
      userAgent: string;
    };
    channel: Channel;
    device?: Device;
    os?: OS;
  };
  id: ID;
  // {{noun(s)}}-{{verb}} (gets more specific as you read from left to right)
  name: string;
  timestamp: number;
  userInteraction?: {
    // 'unknown' means there was one but we're not sure what it was
    // 'time-spent' is a valid option for when the state of something is changed once the user views it for long enough
    name: StringOrUnknownString;
  };
};

export type UserEventWithDataUpdate = WithRequiredMembers<UserEvent, 'dataUpdates'>;

export type StringOrUnknownString = string | 'unknown';

export type AppCategory = 'extension' | 'desktop-app' | 'mobile-app' | 'web-app';
export type AppVersion = StringOrUnknownString;
export type BrowserInfo = {
  engine: StringOrUnknownString;
  name: StringOrUnknownString;
  version: StringOrUnknownString;
};
export type Channel = 'development' | 'production' | string; // e.g. beta
export type Device = {
  model: StringOrUnknownString;
  type: StringOrUnknownString;
  vendor: StringOrUnknownString;
};
export type OS = {
  name: StringOrUnknownString;
  version: StringOrUnknownString;
};

export type PersistentUpdatePatch = Operation[];

export interface PersistentUpdate {
  patch: PersistentUpdatePatch;

  /*
    The order of these is not guaranteed to be in sync with the `patch` property, forwards
    or backwards. If you need to match them up, try the `sortPersistentUpdateInPlace` utility
    function
  */
  reverse: PersistentUpdatePatch;
  timestamp: number;
}

export interface PersistentUpdateForServer extends PersistentUpdate {
  eventForServer: UserEventWithDataUpdate;
}

export interface CmdPalette {
  isOpen: boolean;
  subMenu: SubMenu;
  subMenuParent?: SubMenu;
  feedbackCategory?: FeedbackCategory;
  feedbackSubCategory?: FeedbackSubCategory;
  openSubmenus: SubMenu[];
  subMenuAction?: string;
}

export enum MainTitleType {
  FocusedArticle = 'FocusedArticle',
  AllDocuments = 'AllDocuments',
  AllDocumentsInList = 'AllDocumentsInList',
  AboveFocusedDoc = 'AboveFocusedDoc',
  BelowFocusedDoc = 'BelowFocusedDoc',
  Reader = 'Reader',
  Rss = 'Rss',
  EditFeed = 'EditFeed',
  EditFilteredView = 'EditFilteredView',
}

export type StateSyncingOptions = {
  database: Database;
  cacheKeyPrefix?: string;
  getCurrentPersistentStateWithDocuments: (
    database: Database,
    filterDocumentIds?: DocumentId[],
  ) => Promise<PersistentStateWithDocuments | undefined>;
  onLoggedOut: () => Promise<void>;
  onNewUpdatesForForeground?: (updates: PersistentUpdate[]) => Promise<void>;
  runStateChecksum: (expectedChecksum: number) => boolean;
  shouldSkipSearchIndexing?: boolean;
  isBookwise?: boolean;
};

export enum BulkActionType {
  AllDocs = 'AllDocs',
  AboveFocusedDoc = 'AboveFocusedDoc',
  BelowFocusedDoc = 'BelowFocusedDoc',
}

export enum RightSidebarVisiblePanel {
  DocumentInfo = 'Info',
  DocumentNotebook = 'Notebook',
  NotebookParentInfo = 'Parent Info',
  DocNote = 'DocNote',
}

export interface FeedStats {
  docsCount: number;
  lastUpdated?: number;
}

export interface FeedsStats {
  [feedId: string]: FeedStats;
}

export interface PdfThumbnails {
  [pageNumber: number]: string;
}

export interface PdfsThumbnailPromise {
  pageNumber: number;
  src: string;
}

export interface PdfsThumbnailsCache {
  [docId: string]: {
    [pageNumber: number]: Promise<PdfsThumbnailPromise> | undefined;
  };
}

export type FocusedHighlightIdState = {
  focusedHighlightId: string | null;
  update: (updater: (state: FocusedHighlightIdState) => void) => void;
};

export type UserEventsState = {
  userEvents: UserEvent[]; // oldest first
};

export type AnchorScrollTarget = {
  url: string;
  anchorText: string;
};

// eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
export enum PDFRotation {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  R_0 = 0,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  R_90 = 90,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  R_180 = 180,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  R_270 = 270,
}

export enum NotebookKind {
  SingleParent = 'parent',
  // TODO: highlight review-based notebook
}

export type NonEmptyString = string & { trim(): string; };

/**
 * A function that converts an RxDocument to our formatted type.
 */
export type ConvertRxDocument<T, TResult extends T = T> = (
  rxCollection: RxCollection<T>,
  rxDocument: RxDocument<T>,
) => DeepReadonly<TResult>;


export enum YouTubeEnhancedTranscriptStatus {
  Pending = 'pending',
  NeedsToDownload = 'needs-to-download',
  Done = 'done',
}

export type FilteredViewCategory = Omit<
  typeof Category,
  'Note' | 'Highlight' | 'RSS'
>;

export type SidebarCategory =
  | FilteredViewCategory[keyof FilteredViewCategory]
  | Category.RSS
  | 'library';

export type CategoryQueryMap = {
  [K in FilteredViewCategory[keyof FilteredViewCategory]]: string[];
};

export type FrameData = {
  fps: number;
  lastStamp: number;
  framesCount: number;
  average: number;
  totalCount: number;
};

/**
 * Returns the current and average frames per second.
 */
export type Fps = { average: FrameData['average']; fps: FrameData['fps']; };
