
// eslint-disable-next-line import/no-cycle
import { getScrollingManager } from '../../mobile-library/contentFrameInternals/getScrollingManager';
import { isWebApp } from '../../utils/environment';
import { ScrollingManagerCore } from './coreScrollingManager';
import { WebScrollingManager } from './webScrollingManager';


export function getCoreScrollingManager(): ScrollingManagerCore {
  let scrollingManagerInstance: ScrollingManagerCore | null = null;
  if (!scrollingManagerInstance) {
    if (isWebApp) {
      scrollingManagerInstance = new WebScrollingManager();
    } else {
      const nativeScrollingManager = getScrollingManager();
      scrollingManagerInstance = nativeScrollingManager;
    }
  }

  return scrollingManagerInstance;
}
