import sum from 'lodash/sum';

// eslint-disable-next-line import/no-cycle
import { PaginatedScrollingManager } from '../../mobile-library/contentFrameInternals/PaginatedScrollingManager';
import exceptionHandler from '../../utils/exceptionHandler.platform';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';
import type { ChunkContainerElement } from '../types/chunkedDocuments';
import convertRangeToRangyRange from '../utils/convertRangeToRangyRange';
import getRangyClassApplier from '../utils/getRangyClassApplier';
import { deserializeCanonicalRange, serializeRangeAsCanonical } from '../utils/locationSerialization/chunked';
import { makeWebviewLogger } from '../utils/makeWebviewLogger';
import { forceContentLoadForContainer, getAllChunkContainerElements, getChunkContainersRoot } from './chunkedContent';
import { ScrollingManagerCore } from './coreScrollingManager';
import { getCoreScrollingManager } from './getCoreScrollingManager';

/**
 * Find-in-document highlights and navigates to occurrences of a given query string in a document e.g. "sunshine".
 *
 * Occurrences are represented as serialized ranges e.g. '0/13:50,0/13:58' so that when the chunk containing it unloads,
 * saved occurrences are still valid (unlike DOM Ranges). The array of occurrences is generated and memoized per-chunk.
 *
 * When navigating to an occurrence, we pre-populate next and previous occurrences so that navigating thru the
 * document's occurrences stays smooth.
 */

type ChunkOccurrences = {
  container: ChunkContainerElement;
  occurrences: string[] | null;
};

const logger = makeWebviewLogger(__filename, { shouldLog: false });

declare let window: WindowWithAPIsMissingFromTypeScript;

const SEARCH_RESULTS_CLASS = 'search-results';
const SEARCH_RESULTS_ACTIVE_CLASS = 'search-results-active';

let foundOccurrencesInChunk: ChunkOccurrences[] | undefined;
let storedContentRoot: Element | undefined;
let storedQuery: string | undefined;
let scrollingManager: ScrollingManagerCore | undefined;

const isUsingCssHighlights = Boolean(CSS.highlights);

export function findInDocumentReset() {
  logger.debug('Resetting find-in-document');
  foundOccurrencesInChunk = undefined;
  storedContentRoot = undefined;
  storedQuery = undefined;

  if (isUsingCssHighlights) {
    CSS.highlights.clear();
  } else {
    clearOldHighlighters();
  }
}

export async function findInDocumentInit(query: string) {
  logger.debug('Initializing find-in-document', { query });
  portalGateToForeground.emit('find-in-document-started');
  findInDocumentReset();
  scrollingManager = getCoreScrollingManager();
  if (query === '') {
    portalGateToForeground.emit('find-in-document-finished');
    return;
  }

  const chunkContainers = await getAllChunkContainerElements();
  foundOccurrencesInChunk = chunkContainers.map((container) => ({
    container,
    occurrences: null,
  }));
  storedContentRoot = await getChunkContainersRoot();
  storedQuery = query;
  for (const container of chunkContainers) {
    const occurrences = await populateOccurrencesInChunk(container);
    if (occurrences.length > 0) {
      logger.debug(
        'Found occurrences in chunk, navigating to first occurrence',
        { occurrences, container, query },
      );
      // NOTE: this will also populate the previous and next chunks with occurrences.
      findInDocumentGoToOccurrence(0);
      break;
    }
  }

  portalGateToForeground.emit('find-in-document-finished');
}

async function populateOccurrencesInChunk(container: ChunkContainerElement): Promise<string[]> {
  if (!storedQuery || !foundOccurrencesInChunk) {
    exceptionHandler.captureException('Find in document not initialized');
    return [];
  }
  logger.debug(
    'Populating occurrences in chunk',
    {
      container, foundOccurrencesInChunk,
    },
  );
  if (container.childNodes.length === 0) {
    await forceContentLoadForContainer(container);
  }
  if (!storedQuery || !foundOccurrencesInChunk) {
    logger.debug('Find in document reset while populating occurrences, bailing');
    return [];
  }
  const occurrences = executeFindInChunk(storedQuery, container);
  if (!foundOccurrencesInChunk) {
    exceptionHandler.captureException('Find in document reset while populating occurrences');
    return [];
  }
  const chunkIndex = Number(container.dataset.chunkIndex);
  foundOccurrencesInChunk[chunkIndex].occurrences = occurrences;

  const count = sum(foundOccurrencesInChunk.map(({ occurrences }) => occurrences?.length ?? 0));
  const isExhaustive = foundOccurrencesInChunk.every(({ occurrences }) => occurrences !== null);
  logger.debug('Reporting total occurrence count', { count, foundOccurrencesInChunk, isExhaustive });
  portalGateToForeground.emit('find-in-document-occurrence-count-updated', { count, isExhaustive });
  return occurrences;
}

function executeFindInChunk(query: string, container: ChunkContainerElement): string[] {
  const startTime = performance.now();
  // core function which takes a chunk container and finds all occurrences of given `query` inside it.
  // occurrences are returned as a list of serialized ranges so that even when the chunk container unloads,
  // the occurrence ranges stay valid.
  if (!container.parentElement) {
    throw new Error('orphaned chunk container');
  }
  const treeWalker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, {
    acceptNode(node) {
      return node.parentElement?.tagName === 'STYLE'
        ? NodeFilter.FILTER_REJECT
        : NodeFilter.FILTER_ACCEPT;
    },
  });

  const searchRegex = new RegExp(`(${query})`, 'gi');

  treeWalker.nextNode(); // advance to first child node

  const occurrences: string[] = [];
  const classApplier = getRangyClassApplier();

  for (let node: Node | null = treeWalker.currentNode; node !== null; node = treeWalker.nextNode()) {
    if (!node.textContent) {
      continue;
    }
    while (searchRegex.exec(node.textContent) !== null) {
      const range = new Range();
      range.setStart(node, searchRegex.lastIndex - query.length);
      range.setEnd(node, searchRegex.lastIndex);
      const serializedRange = serializeRangeAsCanonical({
        classApplier,
        range: convertRangeToRangyRange(range),
        containerNode: container.parentElement,
      });
      occurrences.push(serializedRange);
    }
  }

  logger.debug(
    'Executed find in chunk',
    { duration: performance.now() - startTime, query, container, occurrences },
  );
  return occurrences;
}

function highlightAllOccurrencesInChunk(chunk: ChunkOccurrences) {
  if (!isUsingCssHighlights || !storedContentRoot) {
    return;
  }

  const occurrences = chunk.occurrences;
  if (!occurrences || occurrences.length === 0) {
    return;
  }

  const classApplier = getRangyClassApplier();
  const ranges: Range[] = [];

  for (const serializedRange of occurrences) {
    const rangeResult = deserializeCanonicalRange(
      serializedRange,
      storedContentRoot,
      document,
      classApplier,
    );

    if (rangeResult.nativeRange) {
      ranges.push(rangeResult.nativeRange);
    }
  }

  // First clear any existing highlights
  CSS.highlights.delete(SEARCH_RESULTS_CLASS);
  CSS.highlights.delete(SEARCH_RESULTS_ACTIVE_CLASS);

  // Add all occurrences with a limit to prevent crashes
  if (ranges.length > 0) {
    CSS.highlights.set(
      SEARCH_RESULTS_CLASS,
      // Note: this is unlikely to occur in chunks, but kepping this hear as a safeguard
      // rendering more than ~10,000 matches crashes the app due to a stack overflow
      new window.Highlight(...ranges.slice(0, 10000)),
    );
  }
}

function highlightRange(range: Range) {
  if (isUsingCssHighlights) {
    CSS.highlights.delete(SEARCH_RESULTS_ACTIVE_CLASS);
    CSS.highlights.set(
      SEARCH_RESULTS_ACTIVE_CLASS,
      new window.Highlight(range),
      );
  } else {
    highlightRangeWithOldHighlighters(range);
  }
}

function clearOldHighlighters() {
  for (const highlighter of document.getElementsByClassName('search-highlighter')) {
    highlighter.remove();
  }
}

function highlightRangeWithOldHighlighters(range: Range) {
  clearOldHighlighters();

  const rects = range.getClientRects();
  if (!scrollingManager) {
    return;
  }
  const scrollableRootTop = scrollingManager.getScrollingElementTop();
  for (const rect of rects) {
    const searchHighlighter = document.createElement('div');
    searchHighlighter.classList.add('search-highlighter');
    document.body.appendChild(searchHighlighter);

    const posTop = Math.floor(scrollableRootTop + rect.top);
    const width = rect.width;
    const left = rect.left;
    const height = rect.height;

    searchHighlighter.style.top = `${posTop}px`;
    searchHighlighter.style.height = `${height}px`;
    searchHighlighter.style.width = `${width}px`;
    searchHighlighter.style.left = `${left}px`;
    searchHighlighter.classList.add('search-highlighter-pop');
  }
}

async function scrollToOccurrence(occurrence: string) {
  if (!storedContentRoot) {
    exceptionHandler.captureException('Find in document not initialized', {
      extra: {
        occurrence,
      },
    });
    return;
  }
  if (!scrollingManager) {
    exceptionHandler.captureException('Find in document no scrollingManager', {
      extra: {
        occurrence,
        storedContentRoot,
      },
    });
    return;
  }

  const isPaginatedMode = scrollingManager instanceof PaginatedScrollingManager;
  // 300 so that we don't set the scroll position right at the top of the screen
  // disabled in pagination because we want to scroll to the accurate page

  const offset = isPaginatedMode ? 0 : 300;
  const firstPositionInOccurrence = occurrence.split(',')[0];
  await scrollingManager.scrollToSerializedRange(occurrence, offset);

  const classApplier = getRangyClassApplier();
  const range = deserializeCanonicalRange(
    occurrence,
    storedContentRoot,
    document,
    classApplier,
  ).nativeRange;
  logger.debug(
    'Highlighting range',
    { range, occurrence, firstPositionInOccurrence, offset },
  );
  highlightRange(range);
}

async function prepopulateNextAndPreviousOccurrences(aroundChunkIndex: number) {
  if (!foundOccurrencesInChunk) {
    exceptionHandler.captureException(
      'Find in document not initialized',
      { extra: { aroundChunkIndex } },
    );
    return;
  }
  scrollingManager?.updateCurrentCenteredElement();
  // pre-populate next and previous chunks until we find at least one more occurrence
  for (const step of [+1, -1]) {
    const startTime = performance.now();
    let chunkIndex = aroundChunkIndex;
    while (true) {
      chunkIndex = (chunkIndex + step + foundOccurrencesInChunk.length) % foundOccurrencesInChunk.length;
      const chunk = foundOccurrencesInChunk[chunkIndex];
      logger.debug(
        'Prepopulating occurrences in chunk',
        { chunkIndex, chunk, step, aroundChunkIndex },
      );
      if (chunk.occurrences === null) {
        chunk.occurrences = await populateOccurrencesInChunk(chunk.container);
      }
      if (chunk.occurrences.length > 0) {
        logger.debug(
          'Found chunk with occurrences, bailing',
          { chunk, chunkIndex, step, aroundChunkIndex, duration: performance.now() - startTime },
        );
        break;
      }
      if (chunkIndex === aroundChunkIndex) {
        logger.debug(
          'Looped back to the original chunk index, so no occurrences found in entire doc, bailing',
          { chunkIndex, aroundChunkIndex, step, chunk, duration: performance.now() - startTime },
        );
        break;
      }
    }
  }
}

export async function findInDocumentGoToOccurrence(index: number) {
  if (!foundOccurrencesInChunk) {
    exceptionHandler.captureException(
      'Find in document not initialized',
      { extra: { index } },
    );
    return;
  }
  const startTime = performance.now();
  let indexInChunk = index;
  let chunkIndex: number | undefined;
  let targetChunk: ChunkOccurrences | undefined;
  let occurrence: string | undefined;
  for (let i = 0; i < foundOccurrencesInChunk.length; i++) {
    const chunk = foundOccurrencesInChunk[i];
    if (!chunk.occurrences) {
      continue;
    }
    if (indexInChunk < chunk.occurrences.length) {
      targetChunk = chunk;
      occurrence = chunk.occurrences[indexInChunk];
      chunkIndex = i;
      break;
    }
    indexInChunk -= chunk.occurrences.length;
  }
  if (!targetChunk || !occurrence || chunkIndex === undefined) {
    logger.error(
      'No occurrence at index',
      { index, foundOccurrencesInChunk },
    );
    return;
  }
  await forceContentLoadForContainer(targetChunk.container);

  highlightAllOccurrencesInChunk(targetChunk);

  scrollToOccurrence(occurrence);
  prepopulateNextAndPreviousOccurrences(chunkIndex);
  logger.debug(
    'Navigated to occurrence',
    { index, occurrence, targetChunk, indexInChunk, chunkIndex, duration: performance.now() - startTime },
  );
}

